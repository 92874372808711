<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Картка акції</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6">
            <div class="mt-6" v-if="currentTypeParams && currentTypeParams.is_active && !currentTypeParams.is_active.hide">
              <v-switch
                color="success"
                v-model="promotion.is_active"
                :label="promotion.is_active ? 'Включено' : 'Виключено'"
                disabled
              />
            </div>
            <div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">ID акції:</span>
                <span class="font-weight-medium">{{ promotion.id }}</span>
              </div>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.title && !currentTypeParams.title.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Назва:</span>
              <span class="font-weight-medium">{{ promotion.title }}</span>
            </div>
            <div v-if="currentTypeParams && currentTypeParams.start_at && !currentTypeParams.start_at.hide">
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Термін дії з:</span>
                <span class="font-weight-medium">{{ promotion.start_at | getShortDate }}</span>
              </div>
            </div>
            <div v-if="currentTypeParams && currentTypeParams.expired_at && !currentTypeParams.expired_at.hide">
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Термін дії до:</span>
                <span class="font-weight-medium">{{ promotion.expired_at | getShortDate }}</span>
              </div>
            </div>
            <div class="d-flex align-baseline custom-field">
              <span class="mr-1">Тип акції:</span>
              <span class="font-weight-medium">{{ getPromType(promotion.type) }}</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.created_by && !currentTypeParams.created_by.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Ініціатор:</span>
              <span class="font-weight-medium">{{ promotion.created_by.first_name }} {{ promotion.created_by.last_name }}</span>
            </div>
            <div v-if="currentTypeParams && currentTypeParams.conditions && !currentTypeParams.conditions.hide" class="my-3">
              <ConditionsArray :values="promotion.conditions" :params="currentTypeParams.conditions" readonly />
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.users && !currentTypeParams.users.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Клієнти:</span>
              <span class="font-weight-medium">{{
                promotion.users ? promotion.users.map((item) => `${item.first_name} ${item.last_name}`).join(', ') : 'Всі'
              }}</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.service_points && !currentTypeParams.service_points.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Торгові точки:</span>
              <span class="font-weight-medium">{{
                promotion.service_points && promotion.service_points.length
                  ? promotion.service_points.map((item) => item.name).join(', ')
                  : 'Всі'
              }}</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.device_types && !currentTypeParams.device_types.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Типи автоматів:</span>
              <span class="font-weight-medium">{{
                promotion.device.types && promotion.device.types ? promotion.device.types.join(', ') : 'Всі'
              }}</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.devices && !currentTypeParams.devices.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Автомати:</span>
              <span v-if="promotion.devices && promotion.devices.length" class="font-weight-medium">
                <router-link
                  v-for="device in promotion.devices"
                  :key="device.id"
                  :to="{ name: 'DeviceCard', params: { deviceId: device.id } }"
                  class="text-decoration-none"
                >
                  {{ getDeviceTitle(device.type) }}{{ device.id }}&nbsp;&nbsp;
                </router-link>
              </span>
              <span v-else>Всі</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.value_type && !currentTypeParams.value_type.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Вид бонуса:</span>
              <span class="font-weight-medium">{{ getValueType(promotion.value_type) }}</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.amount && !currentTypeParams.amount.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Розмір бонуса:</span>
              <span class="font-weight-medium">{{ promotion.amount }}</span>
            </div>
            <div
              v-if="currentTypeParams && currentTypeParams.description && !currentTypeParams.description.hide"
              class="d-flex align-baseline custom-field"
            >
              <span class="mr-1">Опис:</span>
              <div v-html="promotion.description" class="font-weight-medium"></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mt-5" flat>
      <Payments />
    </v-card>
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton'
import { mapActions, mapState } from 'vuex'
import cloneDeep from '@/mixins/cloneDeep'
import convertDate from '@/mixins/convertDate'
import { PROMOTION_PARAMS, valueTypes } from '@/const/promotionParams'
import ConditionsArray from '@/components/marketing/promotions/ConditionsArray'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'

export default {
  name: 'PromotionCard',
  components: {
    ConditionsArray,
    BackButton,
    Payments: () => import('@/components/common/Payments'),
  },

  mixins: [cloneDeep, convertDate, convertDevicesTypes],

  data() {
    return {
      payload: null,
      isAdmin: true,
      currentTypeParams: null,
    }
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('promotions', ['promotion']),
    ...mapState('dictionaries', [
      'companyPromotionsDictionary',
      'connectedDevicesTypesDictionary',
      'machinesTypesDictionary',
      'globalPromotionsDictionary',
    ]),

    currentCompanyId() {
      return this.$route.params.id
    },

    promotionId() {
      return this.$route.params.promotionId
    },

    isGlobal() {
      return !this.promotion.company
    },
  },

  methods: {
    ...mapActions('promotions', ['loadSelectedPromotion']),

    async initialize() {
      if (!this.promotionId) return
      this.payload = {
        companyId: this.currentCompanyId,
        promotionId: this.promotionId,
        isAdmin: this.isAdmin,
      }
      await this.loadSelectedPromotion(this.payload)
      const params = PROMOTION_PARAMS.find((item) => item.type === this.promotion.type)
      if (!params) return
      this.currentTypeParams = params
      this.isLoaded = true
    },

    getPromType(type) {
      const prom = this.isGlobal
        ? this.globalPromotionsDictionary.find((item) => item.value === type)
        : this.companyPromotionsDictionary.find((item) => item.value === type)
      return prom?.name || null
    },

    getValueType(type) {
      const val = valueTypes.find((item) => item.id === type)
      return val?.value || null
    },
  },
}
</script>
